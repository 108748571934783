import { getApolloContext, useSuspenseQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import { FetchMyIdentityDocument } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { Loading } from '@salescore/client-common'
import { SelectUser } from '@salescore/client-feature-setting'
import { useRedirect } from '@salescore/frontend-common'
import { Button } from 'antd'
import Link from 'next/link'
import { type ReactNode, useContext, useEffect } from 'react'

import { AuthenticatedApolloProvider } from '../providers/AuthenticatedApolloProvider'

const UserNotFound = (): ReactNode => (
  <div className="flex size-full flex-col items-center justify-center text-center align-middle text-gray-400">
    <div>
      このアカウントが所属する組織はありません。
      <br />
      組織設定がまだの方は、弊社営業担当者までお問い合わせください。
      <br />
      既に組織に所属している場合は、違うアカウントで再度ログインを試してください。
    </div>
    <div className="mt-4">
      <Link href={routes.signOutPath()} legacyBehavior>
        <Button type="primary">ログインページへ</Button>
      </Link>
    </div>
  </div>
)

const Redirecting = ({ path }: { path: string }): ReactNode => {
  const redirect = useRedirect()
  useEffect(() => {
    redirect(path)
  }, [])

  return <Loading message={`リダイレクト中...`} />
}

const Body = (): ReactNode => {
  const {
    data: { myIdentity },
  } = useSuspenseQuery(FetchMyIdentityDocument)
  const { client } = useContext(getApolloContext())

  useEffect(() => {
    // 古いキャッシュを読み込み続けてエラーになるケースがあるため、キャッシュクリアする
    void client?.resetStore()
  }, [])

  // 新規登録時、Auth0からリダイレクトされた段階ではAuth0アカウントがあり、Identityがない状態
  if (isNull(myIdentity)) {
    return <Redirecting path={routes.signUpPath()} />
  }

  if (myIdentity.users.length > 1) {
    return <SelectUser myIdentity={myIdentity} />
  }
  const user = myIdentity.users.first()

  if (user?.organization.id !== undefined) {
    const organization = myIdentity.users[0]!.organization
    const organizationId = organization.id
    return <Redirecting path={routes.topPathWithOrganizationIdV2(organizationId)} />
  }
  return <UserNotFound />
}

const Page = (): ReactNode => {
  return (
    <AuthenticatedApolloProvider>
      <Body />
    </AuthenticatedApolloProvider>
  )
}

export default Page
